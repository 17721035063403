<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Users'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'User Edit'"
      :title="'User Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">Seo Name</p>
          <div class="input-container">
            <InputText
              :onChange="setSeoName"
              :id="'seo_name'"
              :label="'seo_name'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'Seo Name'"
              :error-messages="validationErrors['seoName']"
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox
              :label="'Show on team page'"
              :id="'show_on_team_page'"
              :set-value="formDataToSend"
            />
          </div>
          <p class="form-create__label">Description</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'description'"
              :label="'description'"
              :placeholder="''"
              :error-messages="validationErrors['description']"
              isBordered
            />
          </div>

          <p class="form-create__label">Dribble Link</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'dribble_link'"
              :label="'dribble_link'"
              :type="'text'"
              :placeholder="'Dribble Link'"
              :error-messages="validationErrors['dribble_link']"
              isBordered
            />
          </div>

          <p class="form-create__label">Behance Link</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'behance_link'"
              :label="'behance_link'"
              :type="'text'"
              :placeholder="'Behance Link'"
              :error-messages="validationErrors['behance_link']"
              isBordered
            />
          </div>

          <p class="form-create__label">Instagram Link</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'instagram_link'"
              :label="'instagram_link'"
              :type="'text'"
              :placeholder="'Instagram Link'"
              :error-messages="validationErrors['instagram_link']"
              isBordered
            />
          </div>

          <p class="form-create__label">Facebook Link</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'facebook_link'"
              :label="'facebook_link'"
              :type="'text'"
              :placeholder="'Facebook Link'"
              :error-messages="validationErrors['facebook_link']"
              isBordered
            />
          </div>

          <p class="form-create__label">Email</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'email'"
              :label="'email'"
              :type="'text'"
              :placeholder="'Email'"
              :error-messages="validationErrors['email']"
              isBordered
            />
          </div>
          <p class="form-create__label">Password</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'password'"
              :label="'password'"
              :type="'password'"
              :placeholder="'Password'"
              :error-messages="validationErrors['password']"
              isBordered
            />
          </div>
          <p class="form-create__label">Groups</p>
          <div class="input-container checkbox-container">
            <InputRadio
              :set-value="formDataToSend"
              :id="'group'"
              :values="userGroupsList"
              :name="'name'"
              :value="'id'"
            />
            <Notification :error-messages="validationErrors['group']" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Update</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import usersApi from "~/api/user";
import { seoTransform } from "~/helpers/seoTransform";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import Notification from "~/components/molecules/Notification.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";

export default {
  name: "UserEdit",
  metaInfo: {
    title: "User Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        email: "",
        password: "",
        group: null,
        seo_name: "",
        description: "",
        show_on_team_page: 0,

        dribble_link: "",
        behance_link: "",
        instagram_link: "",
        facebook_link: "",
      },
      groups: [],
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    InputRadio,
    Notification,
    MainCheckbox,
    InputTextarea,
  },
  mounted() {
    this.getGroups();
    this.getItem();
  },
  computed: {
    ...mapState("users", ["error", "loadingProcess", "validationErrors", "userGroupsList"]),
    getFirstLink() {
      return ROUTE.USERS_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getGroups() {
      let url = "";
      this.$store.dispatch("users/getUserGroupsList", url);
    },
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        email: this.formDataToSend.email.length ? this.formDataToSend.email : "",
        seo_name: this.formDataToSend.seo_name.length ? this.formDataToSend.seo_name : "",
        description: this.formDataToSend.description.length ? this.formDataToSend.description : "",

        dribble_link: this.formDataToSend.dribble_link.length
          ? this.formDataToSend.dribble_link
          : "",
        behance_link: this.formDataToSend.behance_link.length
          ? this.formDataToSend.behance_link
          : "",
        instagram_link: this.formDataToSend.instagram_link.length
          ? this.formDataToSend.instagram_link
          : "",
        facebook_link: this.formDataToSend.description.length
          ? this.formDataToSend.facebook_link
          : "",

        show_on_team_page: this.formDataToSend.show_on_team_page,
        group_id: this.formDataToSend.group,
      };
      if (this.formDataToSend.password.length) {
        data.password = this.formDataToSend.password.length ? this.formDataToSend.password : "";
      }
      const url = `/${this.getId}`;
      this.$store.dispatch("users/editUser", { url: url, editData: data });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.USERS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    setSeoName(data) {
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        usersApi.getItemUser(url).then((res) => {
          this.$store.commit("users/setError", {});
          this.$store.commit("users/setValidationErrors", {});
          const data = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.email = data.email;
          this.formDataToSend.seo_name = data.seoName || "";
          this.formDataToSend.description = data.description || "";

          this.formDataToSend.dribble_link = data.dribbleLink || "";
          this.formDataToSend.behance_link = data.behanceLink || "";
          this.formDataToSend.instagram_link = data.instagramLink || "";
          this.formDataToSend.facebook_link = data.facebookLink || "";

          this.formDataToSend.show_on_team_page = data.showOnTeamPage;
          this.formDataToSend.group = data.groups[0].id;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
